import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals'
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import Gradient from './component/gradient.component/gradient.component';
import CwComponent from './component/cw.component/CwComponent';
import NavbarComponent from './component/navbar.component/Navbar';
import FooterComponent from "./component/footer.component/FooterComponent"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Router>
  <NavbarComponent/>    
    <Routes>
    <Route exact path='/' element={<Gradient/>} />
        <Route path='/cw' element={<CwComponent />} />
      </Routes>
      <FooterComponent/>
      </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
