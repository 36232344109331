import React from 'react';
import TableComponent from '../table.component/table';
import "./gradient.style.css"

const Gradient = () => {
  // // Rastgele iki renk seçimi
  // const colors = [
  //   `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})`,
  //   `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})`
  // ];

  // // Gradient stilini oluşturma
  // const gradientStyle = {
  //   background: `linear-gradient(-45deg, ${colors[0]}, ${colors[1]})`,
  // };

  return <div className="gradient">

    <TableComponent/> 
    </div>;
};

export default Gradient;