import React, { useState } from "react";
import "./tableStyles.css";
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";

function TableComponent() {
  const [data, setData] = useState([
    { col1: "", col2: "", col3: "", col4: "", col5: "",col6:"",col7:"",col8:"Awb"  },
    { col1: "", col2: "", col3: "", col4: "", col5: "",col6:"",col7:"" ,col8:"Rate"  },
    { col1: "", col2: "", col3: "", col4: "", col5: "",col6:"",col7:"",col8:"Locals (Min/*Kgs)"  },
    { col1: "", col2: "", col3: "", col4: "", col5: "",col6:"",col7:"" ,col8:"Locals (Min/*Kgs)"  },
    { col1: "", col2: "", col3: "", col4: "", col5: "",col6:"",col7:"" ,col8:"Locals (Min/*Kgs)"  },
  ]);

  const handleChange = (e, index) => {
    const newData = [...data];
    newData[index][e.target.name] = e.target.value;
    setData(newData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(data);
  };

  const clearForm = () => {
    setData([
      { col1: "", col2: "", col3: "", col4: "", col5: "",col6:"",col7:"",col8:"Awb" },
      { col1: "", col2: "", col3: "", col4: "", col5: "",col6:"",col7:"",col8:"Rate"},
      { col1: "", col2: "", col3: "", col4: "", col5: "",col6:"",col7:"",col8:"Locals (Min/*Kgs)"},
      { col1: "", col2: "", col3: "", col4: "", col5: "",col6:"",col7:"",col8:"Locals (Min/*Kgs)"},
      { col1: "", col2: "", col3: "", col4: "", col5: "",col6:"",col7:"",col8:"Locals (Min/*Kgs)"},
    ]);
  };

  const addRow = () => {
    setData([...data, { col1: "", col2: "", col3: "", col4: "", col5: "",col6:"",col7:"" }]);
  };

  const calculateVolumeWeight = (col1, col2, col3, col4) =>(col1 * col2 * col3 * col4) / 6000;
  const calculateVolume = (col1, col2, col3, col4) =>(((col2 * col3 * col4) / 1000000) * col1).toFixed(3);
  const calculateAwb = (col6,col7) => (totalCol8 * col7) < col6 ? col6 : (totalCol8 * col7)
  // const calculateTotalCW = (col1,col2,col3,col4,col5) => col5 >= calculateVolumeWeight(col1, col2, col3, col4) ? col5 : calculateVolumeWeight(col1, col2, col3,col4)
  const totalCol1 = data.reduce((acc, curr) => acc + Number(curr.col1), 0);
  const totalCol5 = data.reduce((acc, curr) => acc + Number(curr.col5), 0);
  const totalCol6 = data.reduce((acc, curr) => acc + Number(calculateVolumeWeight(curr.col1, curr.col2, curr.col3, curr.col4)),0).toFixed(3);
  const totalCol7 = data.reduce((acc, curr) => acc + Number(calculateVolume(curr.col1, curr.col2, curr.col3, curr.col4)),0).toFixed(3);
  const totalCol8 = totalCol5 > totalCol6 ? totalCol5 : totalCol6;
  const totalCol9 = data.reduce((acc, curr) => acc + Number(calculateAwb(curr.col6, curr.col7, )),0).toFixed(2)

 
  




  return (
    <div>
        {/* Result Table Start */}
       <div className=" mt-1 table-result" responsive="sm">
          <MDBTable className="table-light table" bordered striped hover align="middle" responsive="sm" >
            <MDBTableHead>
              <tr>
                <th className="fs-5">Total Pieces</th>
                <th className="fs-5">Total Gross(kg)</th>
                <th className="fs-5">Total Volume Weight</th>
                <th className="fs-5">Total Volume Cbm</th>
                <th className="fs-5">Total CW</th>
                <th className="fs-5">All in </th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr>
                <td className="fs-5">{totalCol1}</td>
                <td className="fs-5">{totalCol5}</td>
                <td className="fs-5">{totalCol6}</td>
                <td className="fs-5">{totalCol7}</td>
                <td className="fs-5">{totalCol8}</td>
                <td className="fs-5">{totalCol9}</td>
              </tr>
            </MDBTableBody>
          </MDBTable>
        </div>
        {/* Result Table End */}


{/* Mobile Start */}
        <div className="mobile">
          <h1>Lütfen Ekranı <br></br> Yan Çevirin</h1>
          <i class="fas fa-arrow-rotate-right fa-2x"></i>
        </div>
{/* Mobile End */}


<div className="allin-container">
  {/* Cw Calculate Start */}
        <div className="row-sm" responsive="sm">
          <form onSubmit={handleSubmit}>
            <div className="table-container mb-5" responsive="sm">
              <MDBTable className="table-light table " bordered striped hover align="middle" responsive="sm">
                <MDBTableHead>
                  <tr>
                    <th className="fs-5">Pieces</th>
                    <th className="fs-5">Length(cm)</th>
                    <th className="fs-5">Width(cm)</th>
                    <th className="fs-5">Height(cm)</th>
                    <th className="fs-5">Gross(kg)</th>
                    <th className="fs-5">Volume Weight</th>
                    <th className="fs-5">Volume Cbm</th>
                  </tr>
                </MDBTableHead>

                <MDBTableBody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      <td><input type="number" name="col1" value={row.col1} onChange={(e) => handleChange(e, index)} className="form-control" id="formWhite"/></td>
                      <td><input type="number" name="col2" value={row.col2} onChange={(e) => handleChange(e, index)} className="form-control" id="formWhite"/></td>
                      <td><input type="number" name="col3" value={row.col3} onChange={(e) => handleChange(e, index)} className="form-control" id="formWhite"/></td>
                      <td><input type="number" name="col4" value={row.col4} onChange={(e) => handleChange(e, index)} className="form-control" id="formWhite"/></td>
                      <td><input type="number" name="col5" value={row.col5} onChange={(e) => handleChange(e, index)} className="form-control" id="formWhite"/></td>
                      <td className="value">{calculateVolumeWeight(row.col1,row.col2,row.col3,row.col4,)}</td>
                      <td className="value">{calculateVolume(row.col1,row.col2,row.col3,row.col4)}</td>
                    </tr>
                  ))}
                </MDBTableBody>
                <MDBTableBody>
                  <tr>
                    <td className="fs-5">{totalCol1}</td>
                    <td className="fs-5">{""}</td>
                    <td className="fs-5">{""}</td>
                    <td className="fs-5">{""}</td>
                    <td className="fs-5">{totalCol5}</td>
                    <td className="fs-5">{totalCol6}</td>
                    <td className="fs-5">{totalCol7}</td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </form>
        </div>
        {/* Cw Calculate End*/}


        {/* All-In Calculate Start*/}
        <div className="row-sm">
          <form onSubmit={handleSubmit}>
            <div className="table-container  mb-5" responsive="sm">
              <MDBTable className="table-light table " bordered striped hover align="middle" responsive="sm">
                <MDBTableHead>
                  <tr className="lh-sm">
                    <th className="fs-5">All in</th>
                    <th className="fs-5">Min</th>
                    <th className="fs-5">CW/Kgs </th>
                    <th className="fs-5">Usd</th>
                  </tr>
                </MDBTableHead>
                
                <MDBTableBody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      <td className="bold">{row.col8}</td>
                      <td><input type="number" name="col6" value={row.col6} onChange={(e) => handleChange(e, index)} className="form-control" id="formWhite"/></td>
                      <td><input type="number" name="col7" value={row.col7} onChange={(e) => handleChange(e, index)} className="form-control" id="formWhite"/></td>
                      <td className="value">{calculateAwb(row.col6,row.col7)}</td>
                    </tr>
                  ))}
                </MDBTableBody>
                <MDBTableBody>
                  <tr>
                    <td className="fs-5">{""}</td>
                    <td className="fs-5">{""}</td>
                    <td className="fs-5">{""}</td>
                    <td className="fs-5">{totalCol9}</td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </form>
        </div>
        {/* All-In Calculate End*/}
  </div>


        {/* Button Container Start */}
        <div className="mb-4 d-flex justify-content-center">
          <MDBBtn type="button" className="me-1" color="success" onClick={addRow}> Add </MDBBtn>
          <MDBBtn type="button" className="me-1" color="danger" onClick={clearForm}> Clear </MDBBtn>
        </div>
        {/* Button Container End */}
      
    </div>
  );
}
export default TableComponent;
