import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBRipple,MDBCol
} from 'mdb-react-ui-kit';

export default function CwComponent() {
  return (
    <MDBCol align="middle" className="">
    <MDBCard  background='dark' className='opacity-90 text-white w-50 m-5 p-1' border='primary'>
      <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
        <MDBCardImage src='https://herkesicinhavacilik.com/wp-content/uploads/2021/02/KARGOU2.jpg' fluid alt='...' />
        <a> <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div> </a>
      </MDBRipple>
      <MDBCardBody>
        <MDBCardTitle>CW Nedir?</MDBCardTitle>
        <MDBCardText>
        Lojistik sektöründe bulunan havayolu taşımacılığı terimleri içerisinde CW nedir? Açılımı olan Chargeable ne demek?

CW nedir?: Ürünün ağırlığı (CW – Chargeable weight): Havayolunda ürünler genelde KG üzerinden baz alınırken KG ile ölçü arasındaki orantı baz alınarak taşıma ücretinin karar verildiği değerdir. Ürünlerin kilogramı ağır ise fiyat kiloya göre verilir, ürünlerin ölçüleri kg göre daha yüksek ise ölçüye göre fiyat verilir.

Chargeable genelde havayolunda ölçüe ve kg dengesi olarak bilinir. Fiyatlandırma yöntemlerimde etken değerler arasındadır. Çoğu havayolu şirketi ve acenteleri CW ‘ye göre karşılaştırma yaparak taşıma ücretlerini oluştururlar.
        </MDBCardText>

      </MDBCardBody>
    </MDBCard>
    </MDBCol>
  );
}




