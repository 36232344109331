import React from 'react';
import {
  MDBFooter,
} from 'mdb-react-ui-kit';
import "./footerStyles.css"

export default function App() {
  return (
    <MDBFooter className='footer-container bg-light text-center text-dark  fixed '>
      <div className='text-center p-3' >
        Copyright:
        <a className='text-dark' href='https://www.linkedin.com/in/mehmet-tutum-95b37272/'>
           Mehmet Tutum
        </a>
        <br  className='p1 text-dark'/>
        Developer:
        <a className='text-dark' href='https://www.linkedin.com/in/ahmet-akin-tutkan/'>
           Ahmet Akın Tutkan
        </a>
        
      </div>
    </MDBFooter>
  );
}