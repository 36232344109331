import "./navbarStyles.css"
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBDropdownToggle,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
 


} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

export default function App() {
  
  return (
    <div>
    <MDBNavbar className="navbar-container d-flex" expand="lg" light bgColor="light" >
      <MDBContainer fluid>
        <MDBNavbarBrand className="me-auto">
          {<MDBIcon className='ms-1 px-1 text-dark' icon='calculator' size='sm' />} 
          <Link to='/' className="text-dark">Chargeable Weight</Link>
        </MDBNavbarBrand>



        <MDBCollapse navbar >
          <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 d-flex justify-content-end">
            <MDBNavbarItem >
              <MDBNavbarLink active aria-current="page" >
                {<MDBIcon className='ms-1 px-1 text-dark' icon='plane' size='sm' />}
                <Link to='/cw' className="text-dark">Cw Nedir?</Link>
              </MDBNavbarLink>
            </MDBNavbarItem>



            <MDBNavbarItem>
            <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link text-dark' role='button'>
                {<MDBIcon className='ms-1 px-1 text-dark ' icon='info-circle' size='sm' />}
                <Link to="/" className="text-dark">Faydalı Linkler</Link>
             
              
                </MDBDropdownToggle>
                  
                <MDBDropdownMenu >
                  <MDBDropdownItem link ><a className="text-dark" href='https://www.proveltr.com/' target="_blank" rel="noreferrer" >Provel</a></MDBDropdownItem>
                  <MDBDropdownItem link ><a className="text-dark" href='https://turkishcargo.com.tr/tr' target="_blank" rel="noreferrer">Türk Hava Yoları</a></MDBDropdownItem>

                </MDBDropdownMenu>
                  
              </MDBDropdown>  
             
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
    </div>
  );
}
